<template>
  <div id="profil">
    <div class="vx-row">
      <div class="vx-col w-full sm:w-1/3 lg:w-1/3 mb-base">
        <vx-card class="p-0" title="Debugging Aufgaben">
          <div class="vx-row mb-4">
            <div class="vx-col w-full"><b>Verträge erneuern:</b></div>
            <div class="vx-col w-3/5"><datepicker placeholder="Datum wählen" v-model="renew_contracts_date"></datepicker></div>
            <div class="vx-col w-2/5"><vs-button class="w-full" @click="runRenewContracts">Ausführen</vs-button></div>
          </div>

          <div class="vx-row mb-4">
            <div class="vx-col w-full"><b>Verträge kündigen:</b></div>
            <div class="vx-col w-3/5"><datepicker placeholder="Datum wählen" v-model="terminate_contracts_date"></datepicker></div>
            <div class="vx-col w-2/5"><vs-button class="w-full" @click="runTerminateContracts">Ausführen</vs-button></div>
          </div>

          <div class="vx-row mb-4">
            <div class="vx-col w-full"><b>Verträge zur Accounting Liste hinzufügen:</b></div>
            <div class="vx-col w-3/5"><datepicker placeholder="Datum wählen" v-model="accounting_contracts_date" format="yyyy-MM-dd"></datepicker></div>
            <div class="vx-col w-2/5"><vs-button class="w-full" @click="runAccountingContracts">Ausführen</vs-button></div>
          </div>
        </vx-card>
      </div>

      <div class="vx-col w-full sm:w-2/3 lg:w-2/4 mb-base">
        <vx-card title="Debugging Ergebnis">
          <vue-json-pretty
            style="background: rgba(0,49,118,0.05); padding: 10px; border-radius: 4px"
            :path="'res'"
            :data="result_string">
          </vue-json-pretty>
        </vx-card>
      </div>

    </div>
  </div>
</template>


<script>
  import Datepicker from '@sum.cumo/vue-datepicker';
  import {de} from '@sum.cumo/vue-datepicker/src/locale';
  import VueJsonPretty from 'vue-json-pretty'
  import ApiService from '../../api'
  import moment from 'moment'

  export default {
    name: "Profile",
    components: {
      Datepicker,
      VueJsonPretty
    },
    data() {
      return {
        datepicker_lang: de,
        result_string: '',
        renew_contracts_date: moment().format('Y-M-D'),
        terminate_contracts_date: moment().format('Y-M-D'),
        accounting_contracts_date: moment().format('Y-M-D')
      }
    },
    computed: {},
    mounted() {
    },
    methods: {
      runRenewContracts() {
        ApiService.get('testing/renewContracts/'+ moment(this.accounting_contracts_date).format('Y-M-D')).then(response => {
          this.result_string = response.data.result;
        })
      },
      runTerminateContracts() {
        ApiService.get('testing/endTerminatedContracts/'+ moment(this.accounting_contracts_date).format('Y-M-D')).then(response => {
          this.result_string = response.data.result;
        })
      },
      runAccountingContracts() {
        ApiService.get('testing/addContractsToAccountingList/'+moment(this.accounting_contracts_date).format('Y-M-D')).then(response => {
          this.result_string = response.data.result;
        })
      }
    }
  }
</script>

<style lang="scss">
  #profil {
    .vs-tabs--ul {
      .vs-tabs--li {
        .vs-tabs--btn {
          padding: 16px 16px;
        }
      }
    }
  }
</style>
